import { getMoneyToNumber } from "@/utils/stringUtils";

export default class GoodsWriteItemModel {
  constructor(obj) {
    this.objRaw = {};

    this.objItemData = {};

    // "estimateItemList": [
    //   {
    //     "itemGroupSeq": "1",
    //     "itemSeq": 1,
    //     "itemCode": "PC001",
    //     "itemNm": "CPU",
    //     "itemPrice": 400000,
    //     "quantity": 1,
    //     "specification": "INTEL-10g",
    //     "quantityGubunCode": "EA",
    //     "quantityGubunCodeNm": "EA",
    //     "spplrPrice": 390000,
    //     "taxPrice": 39000,
    //     "currencyCode": "KRW"
    //   }
    // ]

    this.itemNm = "";
    this.specification = ""; // 규격
    this.quantity = ""; // 수량
    this.quantityGubunCodeNm = "";
    this.itemPrice = ""; // 단가.
    this.spplrPrice = ""; // 공급가.
    this.taxPrice = ""; // 세액.

    if (obj) {
      this.setData(obj);
    }
  }

  setItemData(obj) {
    this.objItemData = obj;

    const { itemNm, specification, quantity, quantityGubunCodeNm, itemPrice } = obj;

    this.itemNm = itemNm || "";
    this.specification = specification || "";
    this.quantity = quantity || quantity === 0 ? String(quantity) : "";
    this.quantityGubunCodeNm = quantityGubunCodeNm || "";
    this.itemPrice = itemPrice || itemPrice === 0 ? String(itemPrice) : "";
  }

  setData(obj) {
    this.objRaw = obj;

    const {
      itemNm,
      specification,
      quantity,
      quantityGubunCodeNm,
      itemPrice,
      spplrPrice,
      taxPrice,
    } = obj;

    this.itemNm = itemNm || "";
    this.specification = specification || "";
    this.quantity = quantity || quantity === 0 ? String(quantity) : "";
    this.quantityGubunCodeNm = quantityGubunCodeNm || "";
    this.itemPrice = itemPrice || itemPrice === 0 ? String(itemPrice) : "";
    this.spplrPrice = spplrPrice || spplrPrice === 0 ? String(spplrPrice) : "";
    this.taxPrice = taxPrice || taxPrice === 0 ? String(taxPrice) : "";
  }

  changeCalcPrice() {
    const { quantity, itemPrice } = this;

    if (!quantity && !itemPrice) return "0";

    const cal = getMoneyToNumber(quantity) * getMoneyToNumber(itemPrice);

    this.spplrPrice = String(cal);
  }

  setTaxPrice() {
    const nSpplrPrice = getMoneyToNumber(this.spplrPrice);

    const nResult = parseInt(nSpplrPrice * 0.1, 10);

    this.taxPrice = nResult > 0 ? String(nResult) : "0";
  }
  clearTaxPrice() {
    this.taxPrice = "0";
  }

  getData() {
    let obj = {
      itemNm: this.itemNm,
      specification: this.specification,
      quantity: getMoneyToNumber(this.quantity),
      quantityGubunCodeNm: this.quantityGubunCodeNm,
      itemPrice: getMoneyToNumber(this.itemPrice),
      spplrPrice: getMoneyToNumber(this.spplrPrice),
      taxPrice: getMoneyToNumber(this.taxPrice),
      // 임시 테스트
      currencyCode: "KRW",
    };

    return Object.assign({}, this.objItemData, this.objRaw, obj);
  }

  getAlertMessage() {
    if (this.quantity === 0 || this.quantity === "0" || !this.quantity)
      return "품목의 수량을 확인해주세요.";
    if (this.itemPrice !== 0 && !this.itemPrice) return "품목의 단가를 확인해주세요.";

    const { possibleQuantity } = this.objRaw;
    if (possibleQuantity) {
      const nQuantity = Number.parseInt(this.quantity, 10);
      if (nQuantity > possibleQuantity) return "품목의 발행 가능한 수량을 확인해주세요.";
    }

    return null;
  }
}
