<template>
  <tr>
    <td>{{ no + 1 }}</td>
    <td>
      <div class="row_pack">
        <Input
          class="w252"
          :maxLength="100"
          :isDisabled="true"
          placeholder="품목을 조회하여 선택하세요"
          :value.sync="model.itemNm"
        />
        <button
          :disabled="!canModifyItem"
          class="btn_small btn_tertiary"
          @click="$emit('onClickGoodsSearch', onUpdateCount)"
        >
          품목조회
        </button>
      </div>
    </td>
    <td>
      <Input :value.sync="model.specification" :isDisabled="true" />
    </td>
    <td class="align_left">
      <div class="row_pack">
        <InputAutoMoney
          :class="{ w90: model.quantityGubunCodeNm }"
          :maxLength="MAX_LENGTH_QUANTITY"
          :value.sync="model.quantity"
          :isDisabled="isDisabledQuantity"
          data-cy="itemQuantity"
          @update:value="onUpdateCount"
        />
        <!-- <InputNumber
          class="w103"
          :value.sync="model.quantity"
          @update:value="onUpdateCount"
        /> -->
        <span v-if="model.quantityGubunCodeNm" class="txt_view_r">{{
          model.quantityGubunCodeNm
        }}</span>
      </div>
    </td>
    <td>
      <InputAutoMoney
        :isDisabled="!canModifyItemPrice"
        :maxLength="MAX_LENGTH_UNIT_PRICE"
        :value.sync="model.itemPrice"
        data-cy="itemPrice"
        @update:value="onUpdateCount"
      />
    </td>
    <td>
      <InputAutoMoney :isDisabled="true" :value.sync="model.spplrPrice" />
    </td>
    <td>
      <InputAutoMoney :isDisabled="true" :value.sync="model.taxPrice" />
    </td>
    <td>
      <button
        v-if="canRemove"
        type="button"
        class="btn_tertiary btn_small"
        @click="$emit('onClickRemove')"
      >
        삭제
      </button>
    </td>
  </tr>
</template>

<script>
import Input from "@/components/common/input/Input";
import InputAutoMoney from "@/components/common/input/InputAutoMoney";

import GoodsWriteItemModel from "./GoodsWriteItemModel";

import { getMoneyToNumber } from "@/utils/stringUtils";
import { MAX_LENGTH_QUANTITY, MAX_LENGTH_UNIT_PRICE } from "@/constants/commonTextLength";

export default {
  components: {
    Input,
    InputAutoMoney,
  },
  props: {
    no: Number,
    isTaxApply: Boolean,
    model: GoodsWriteItemModel,

    canModifyItemPrice: Boolean,
    canRemove: Boolean,

    canModifyItem: Boolean,

    // isDisableItemCore: Boolean,
  },
  computed: {
    isDisabledQuantity() {
      const { possibleQuantity } = this.model.objRaw;

      if (!possibleQuantity) {
        return false;
      }

      return possibleQuantity < 2;
    },
    MAX_LENGTH_QUANTITY() {
      return MAX_LENGTH_QUANTITY;
    },
    MAX_LENGTH_UNIT_PRICE() {
      return MAX_LENGTH_UNIT_PRICE;
    },
  },
  mounted() {
    // 값과 함께 생성 된 경우 계산 필요.
    this.onUpdateCount();
  },
  methods: {
    onUpdateCount() {
      this.model.changeCalcPrice();

      if (this.isTaxApply) {
        this.model.setTaxPrice();
      }

      this.$emit("onUpdateCount");
    },
  },
};
</script>
