<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 class="tit_section">견적요청상세</h2>
      </div>
      <div class="body_section">
        <EstimateInfo :itemData="itemData" />
        <Fold title="상세내용">
          <TableView>
            <template v-slot:body>
              <tr>
                <th>상세내용</th>
                <td class="h440" colspan="3" v-html="itemData.contents" />
              </tr>
            </template>
          </TableView>
        </Fold>
        <EstimateComplete
          v-if="isCompleteStatus"
          :itemData="itemData"
          :estimateInfoData="itemData ? itemData.estimateInfoData : {}"
        />
        <EstimateNotComplete
          v-else-if="itemData.statusCode"
          ref="estimateNotComplete"
          :itemData="itemData"
          :model="estimateNotCompleteModel"
          :loginCompanyName="loginCompanyName"
          :loginCompanyNo="loginCompanyNo"
          @onClickGoodsSearch="onClickGoodsSearch"
        />
      </div>
    </div>

    <Sticky>
      <div class="area_left">
        <button
          v-if="isCompleteStatus"
          type="button"
          class="btn_tertiary btn_large"
          @click="goToList"
        >
          목록
        </button>
        <button v-else type="button" class="btn_tertiary btn_large" @click="onClickCancel">
          취소
        </button>
      </div>
      <div v-if="!isCompleteStatus" class="area_right">
        <button type="button" class="btn_secondary btn_large" @click="onClickSave(true)">
          임시저장
        </button>
        <button type="button" class="btn_primary btn_large" @click="onClickSave(false)">
          발송
        </button>
      </div>
      <div v-else class="area_right">
        <button type="button" class="btn_secondary btn_large" @click="onClickPreviewPopup">
          미리보기
        </button>
      </div>
    </Sticky>

    <template v-slot:popup>
      <GoodsSearchPopup
        v-if="isPopGoodsSearch"
        widthPopGoods="762px"
        :defaultRadioSelectedSeq="selectedItemModel.objItemData.itemSeq"
        @onClickAdd="onClickAddGoods"
        @onClickClose="onClickCloseGoodsSearchPop"
      />
      <EstimatePrintPopup
        v-if="isPopEstimatePrint"
        :estimateData="itemData"
        @onClickClose="onClickClosePopEstimatePrint"
      />
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import Fold from "@/components/common/fold/Fold";
import TableView from "@/components/shared/tableView/TableView";
import Sticky from "@/components/layout/content/Sticky.vue";

import EstimateInfo from "@/components/shared/estimate/view/EstimateInfo";
import EstimateComplete from "@/components/user/estimate/view/EstimateComplete";
import EstimateNotComplete from "@/components/user/estimate/view/EstimateNotComplete";
import GoodsSearchPopup from "@/components/shared/goodsDetail/popup/GoodsSearchPopup";
import EstimatePrintPopup from "@/components/user/estimate/popup/EstimatePrintPopup";

import EstimateNotCompleteModel from "@/components/user/estimate/view/EstimateNotCompleteModel";
import GoodsWriteItemModel from "@/components/shared/goodsDetail/GoodsWriteItemModel";

import PageMixin from "@/mixins/PageMixin";

import ApiService from "@/services/ApiService";

import { ALERT_ACTION } from "@/store/modules/alert/action";

export default {
  components: {
    PageWithLayout,
    Fold,
    TableView,
    Sticky,

    EstimateInfo,
    EstimateComplete,
    EstimateNotComplete,
    GoodsSearchPopup,
    EstimatePrintPopup,
  },
  mixins: [PageMixin],
  data() {
    return {
      itemData: {},

      estimateNotCompleteModel: new EstimateNotCompleteModel(),

      selectedItemModel: null,
      selectedFunc: null,

      progressDataList: [
        { stateType: "done", stateName: "견적서발송" },
        { stateType: "", stateName: "발주접수대기" },
        { stateType: "", stateName: "납품대기" },
      ],

      isPopGoodsSearch: false,
      isModifyMode: false,
      isPopEstimatePrint: false,
      estimatePrint: null,
      estimateData: null,
    };
  },
  computed: {
    isCompleteStatus() {
      if (!this.itemData) return false;

      const { statusCode } = this.itemData;

      // 견적 회신 상태
      return statusCode === "A";
    },
  },
  beforeMount() {
    const { id } = this.$route.params;

    if (!id) {
      this.$router.push(this.$routerPath.HOME);
      return;
    }

    if (!this.isCompleteStatus) {
      // 공급사 담당자에 공급사 대표자명 기본값.
      this.estimateNotCompleteModel.setDefaultSupplierManagerName(this.loginRepresentativeNm);
    }

    this.estimateNotCompleteModel.setDefaultTelNo(this.loginUserTel);

    this.getData(id);
  },
  methods: {
    async getData(id) {
      const path = `${this.$apiPath.ESTIMATE}/${id}`;

      const result = await ApiService.shared.getData(path);

      const { code, data, message } = result;

      if (code !== "200") {
        this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, message);
        return;
      }

      this.estimateNotCompleteModel.setData(data);

      this.itemData = data;
      this.isModifyMode = data.statusCode === "T";
    },
    async onClickSave(isTemp) {
      const alertMessage = this.getAlertMessage();

      if (alertMessage) {
        this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, alertMessage);
        return;
      }

      const obj = this.getObjToSend();

      const { id } = this.$route.params;

      let path = `${this.$apiPath.ESTIMATE}/${id}?temp=${isTemp}`;

      const onComplete = (response) => {
        const { code, message } = response.data;

        if (code !== "200") {
          this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, message);
          return;
        }

        this.goToList();
      };

      const { statusCode } = this.itemData;

      // if (this.isModifyMode) {
      //   http.put(path, obj).then(onComplete);
      // } else {
      //   http.post(path, obj).then(onComplete);
      // }

      if (this.isModifyMode) {
        const { id } = this.$route.params;

        path = `${this.$apiPath.QUOTATION}/${id}?temp=${isTemp}`;
        const result = await ApiService.shared.putData(path, obj);
        const { code, message } = result;

        if (code !== "200") {
          this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, message);
          return;
        }
      } else {
        path = `${this.$apiPath.QUOTATION}?temp=${isTemp}`;
        const result = await ApiService.shared.postData(path, obj);
        const { code, message } = result;

        if (code !== "200") {
          this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, message);
          return;
        }
      }

      this.goToList();
    },
    getAlertMessage() {
      let alertMemssage;

      if (this.isCompleteStatus) {
      } else {
        alertMemssage = this.estimateNotCompleteModel.getAlertMessage();
      }

      if (alertMemssage) return alertMemssage;

      return null;
    },
    getObjToSend() {
      // 완료 상태에는 저장할 일이 없다?
      if (this.isCompleteStatus) return;

      let obj = this.estimateNotCompleteModel.getData();

      const objSum = this.$refs.estimateNotComplete.getPriceSum();

      let ret = Object.assign({}, obj, objSum);

      ret.spplrCompanyRgstNo = this.loginCompanyNo;
      ret.spplrCompanyNm = this.loginCompanyName;
      ret.spplrMngrNm = this.estimateNotCompleteModel.spplrMngrNm;

      ret.estimatePrice = objSum.price;

      return ret;
    },
    onClickCancel() {
      // 변경 된 내용을 체크하지 않기로 정했기에 주석 처리 합니다.
      // this.exitAlert(this.goToList);
      this.onExitAlertOk();

      // const { estimateTitle, remark, discountRate,  spplrMngrNm } = this.estimateNotCompleteModel;
      // const { spplrTelNo, estimateItemList } = this.estimateNotCompleteModel.getData();
      // let checkDiff = false;

      // if( estimateTitle != this.itemData.estimateTitle) checkDiff = true;
      // if( remark != this.itemData.remark) checkDiff = true;
      // if( discountRate != '0') checkDiff = true;
      // if( spplrTelNo != this.loginUserTel) checkDiff = true;
      // if( spplrMngrNm != this.loginRepresentativeNm) checkDiff = true;
      // for(var i=0; i<estimateItemList.length; ++i) {
      //   const { quantity, itemPrice } = estimateItemList[i];
      //   if( quantity != 0 || itemPrice != 0) {
      //     checkDiff =true;

      //     break;
      //   }
      // }

      // if(checkDiff) {
      //   this.exitAlert(this.onExitAlertOk);

      //   return;
      // }

      // this.goToList();
    },
    onExitAlertOk() {
      this.goToList();
    },
    goToList() {
      this.$router.push({
        path: this.$routerPath.ESTIMATE_LIST,
        query: this.$route.query,
      });
    },
    onClickGoodsSearch(itemModel, func) {
      this.selectedItemModel = itemModel;

      this.selectedFunc = func;

      this.isPopGoodsSearch = true;
    },
    onClickCloseGoodsSearchPop() {
      this.isPopGoodsSearch = false;

      this.selectedItemModel = null;
    },
    onClickAddGoods(item) {
      // 이미 있는 품목인지 체크.
      const hasAlready = this.estimateNotCompleteModel.goodsModelList.some(
        (itemPrev) =>
          itemPrev.objRaw.itemSeq === item.itemSeq || itemPrev.objItemData.itemSeq === item.itemSeq,
      );

      if (hasAlready) {
        this.alert("동일 품목은 추가할 수 없습니다.");
        return;
      }

      //this.selectedItemModel.setItemData({ ...item, quantity: 1 });

      // 값이 set 될 때 onupdate 가 안되는 문제. 임시로 콜백으로 처리
      this.selectedFunc();

      // this.selectedItemModel = null;

      this.isPopGoodsSearch = false;
    },
    onClickPreviewPopup() {
      this.estimatePrint = this.itemData;

      // this.printItemData.title = item.estimateTitle;
      this.isPopEstimatePrint = true;
    },
    onClickClosePopEstimatePrint() {
      this.isPopEstimatePrint = false;
    },
  },
};
</script>
