<template>
  <Fold :title="title + '상세품목'">
    <template v-slot:headRight>
      <button v-if="canAdd" type="button" class="btn_secondary btn_medium" @click="onClickAdd">
        품목추가
      </button>
    </template>
    <div class="area_view">
      <Board
        :disableHover="true"
        :dataList="goodDataList"
        :isScroll="true"
        scrollMaxHeight="342px"
        :showPagination="false"
      >
        <template v-slot:colgroup>
          <col style="width: 60px" />
          <!-- NO. -->
          <col style="width: 372px" />
          <!-- 품목 -->
          <col style="width: 118px" />
          <!-- 규격 -->
          <col style="width: 152px" />
          <!-- 수량 -->
          <col style="width: 150px" />
          <!-- 단가 -->
          <col style="width: 150px" />
          <!-- 금액 -->
          <col style="width: 140px" />
          <!-- 세액 -->
          <col style="width: 98px" />
          <!-- 삭제 -->
        </template>
        <template v-slot:tr>
          <th style="width: 60px">번호</th>
          <th style="width: 372px">품목명</th>
          <th style="width: 118px">규격</th>
          <th style="width: 152px">수량<span class="ico_purchase ico_star">필수항목</span></th>
          <th style="width: 150px">단가<span class="ico_purchase ico_star">필수항목</span></th>
          <th style="width: 150px">공급가액</th>
          <th style="width: 140px">세액</th>
          <th style="width: 98px">삭제</th>
        </template>
        <template v-slot:rows>
          <template v-for="(item, index) in goodDataList">
            <GoodsWriteItem
              :key="index"
              :no="index"
              :canRemove="goodDataList.length > 1"
              :canModifyItem="canModifyItem"
              :isTaxApply="isTaxApply"
              :canModifyItemPrice="canModifyItemPrice"
              :model="item"
              @onClickRemove="$emit('onClickRemove', index)"
              @onClickGoodsSearch="(func) => $emit('onClickGoodsSearch', item, func)"
            />
            <!-- @onUpdateCount="onUpdateCount" -->
            <!-- @onClickGoodsSearch="$emit('onClickGoodsSearch', item)" -->
          </template>
        </template>
        <template v-slot:emptyList>
          <tr>
            <td colspan="8" class="td_empty">{{ title }}상세품목이 없습니다.</td>
          </tr>
        </template>
      </Board>
      <TableView>
        <template v-slot:colgroup>
          <col style="width: 192px" />
          <col style="width: 428px" />
          <col style="width: 620px" />
        </template>
        <template v-slot:body>
          <tr>
            <th>세액</th>
            <td class="h71">
              <button
                v-if="canDiscountAndTax"
                class="btn_small btn_tertiary"
                @click="onClickTaxApply"
              >
                {{ isTaxApply ? "세액 적용 취소" : "세액 적용" }}
              </button>
              <div v-else>
                {{ isTaxApply ? "세액 적용" : "세액 미적용" }}
              </div>
            </td>
            <td rowspan="2" class="no_padding">
              <dl class="list_total">
                <dt>공급가액</dt>
                <dd>{{ currencyName | moneyByCurrency(supplyPriceTotal) }}</dd>
                <dt>할인</dt>
                <dd>
                  (-)
                  {{ currencyName | moneyByCurrency(isDiscountApply ? discountPriceTotal : "0") }}
                </dd>
                <dt>세액</dt>
                <dd>{{ currencyName | moneyByCurrency(isTaxApply ? taxTotal : "0") }}</dd>
                <!-- <dd>(+) ₩ {{ isTaxApply ? ( taxTotal | currency ) : '0' }}</dd> -->
                <dt class="dt_total">
                  {{ title }}금액
                  <span class="txt_small">(공급가액{{ TotalSmallText }})</span>
                </dt>
                <dd class="dd_total">
                  <span class="txt_total">일금</span>
                  <span class="txt_korean">
                    <!-- {{ totalPriceData.estimateKoreanPrice? totalPriceData.estimateKoreanPrice+'원정':'-' }} -->
                    {{ sumKorean ? sumKorean + "원정" : "-" }}
                  </span>
                  <span class="txt_price">({{ currencyName | moneyByCurrency(sum) }})</span>
                  <!-- <span class="txt_price">(₩ {{ totalPriceData.estimateCommaPrice }})</span> -->
                </dd>
              </dl>
            </td>
          </tr>
          <tr>
            <th>할인</th>
            <td v-if="canDiscountAndTax" class="h73">
              <div class="row_pack">
                <button class="btn_small btn_tertiary" @click="onClickDiscountApply">
                  {{ isDiscountApply ? "할인 적용 취소" : "할인 적용" }}
                </button>
              </div>
              <div class="row_pack">
                <InputAutoMoney
                  class="w360"
                  :value.sync="discountRateSync"
                  placeholder="할인율(%)을 입력하세요"
                  :isDisabled="isDiscountApply"
                />
                <span class="txt_view_r">%</span>
              </div>
            </td>
            <td v-else class="h73">
              <div>{{ isDiscountApply ? "할인 " + discountRate + "% 적용" : "할인 미적용" }}</div>
            </td>
          </tr>
        </template>
      </TableView>
    </div>
    <slot />
  </Fold>
</template>

<script>
import Fold from "@/components/common/fold/Fold";
import InputAutoMoney from "@/components/common/input/InputAutoMoney";
import Board from "@/components/common/board/Board";
import TableView from "@/components/shared/tableView/TableView";

import GoodsWriteItem from "./GoodsWriteItem";

import { getMoneyToKorean, addCommaForMoney, getMoneyToNumber } from "@/utils/stringUtils";
import { getCanConvertNumber } from "@/utils/numberUtils";

import { ALERT_ACTION } from "@/store/modules/alert/action";

import GoodsWriteItemModel from "@/components/shared/goodsDetail/GoodsWriteItemModel";

export default {
  components: {
    Fold,
    InputAutoMoney,
    Board,
    TableView,
    GoodsWriteItem,
  },
  props: {
    title: String,
    goodDataList: Array,

    isTaxApply: Boolean,
    isDiscountApply: Boolean,

    discountRate: String,

    currencyName: String,

    canAdd: Boolean,
    canDiscountAndTax: Boolean,
    canModifyItemPrice: {
      type: Boolean,
      default: true,
    },
    canModifyItem: Boolean,
  },
  data() {
    return {
      LIMIT_ITEM_COUNT: 10,
    };
  },
  computed: {
    supplyPriceTotal() {
      const reducer = (accumulator, item) => {
        const nVal = getMoneyToNumber(item.spplrPrice);

        return accumulator + nVal;
      };

      const ret = this.goodDataList.reduce(reducer, 0);
      return ret;
    },
    sum() {
      const ret = this.supplyPriceTotal - this.discountPriceTotal + this.taxTotal;
      return ret;
    },
    sumKorean() {
      return getMoneyToKorean(this.sum);
    },
    discountPriceTotal() {
      if (!this.isDiscountApply) return 0;

      if (!this.discountRate) return 0;
      if (!this.supplyPriceTotal) return 0;

      if (!getCanConvertNumber(this.discountRate)) return 0;

      const nDiscountRate = parseFloat(this.discountRate);

      const discountRateFixed = nDiscountRate.toFixed(1);

      const result = this.supplyPriceTotal * (discountRateFixed / 100);

      return parseInt(result);
    },
    taxTotal() {
      if (!this.isTaxApply) return 0;

      const nResult = parseInt((this.supplyPriceTotal - this.discountPriceTotal) * 0.1, 10);

      return nResult > 0 ? nResult : 0;
    },
    TotalSmallText() {
      if (!this.isTaxApply && !this.isDiscountApply) {
        return "";
      } else {
        var discountText = "";
        var taxText = "";
        // if (this.isDiscountApply) {
        //   discountText = '-할인';
        // }
        if (this.isTaxApply) {
          taxText = "+세액";
        }
        const result = taxText;
        return result;
      }
    },
    discountRateSync: {
      get() {
        return this.discountRate;
      },
      set(value) {
        this.$emit("update:discountRate", value);
      },
    },
  },
  beforeMount() {
    if (this.goodDataList && this.goodDataList.length === 0) {
      // this.goodDataList.push(this.createItem());

      this.$emit("update:goodDataList", this.goodDataList.concat(this.createItem()));

      // this.goodDataList.push(this.createItem());
      // this.goodDataList.push(this.createItem());
    }

    // istax, isdiscount 체크
    // 값 업데이트
  },
  methods: {
    getPriceSum() {
      return {
        spplrPriceSum: this.supplyPriceTotal,
        taxPriceSum: this.taxTotal,
        price: this.sum,
        discountRate: this.isDiscountApply ? this.discountRate : "0",
      };
    },
    createItem() {
      const item = new GoodsWriteItemModel();
      return item;
    },
    onClickAdd() {
      if (this.goodDataList.length >= this.LIMIT_ITEM_COUNT) {
        this.$store.dispatch(
          ALERT_ACTION.SHOW_ALERT,
          `총 ${this.LIMIT_ITEM_COUNT}개까지 가능합니다.`,
        );
        return;
      }
      this.addItem();
    },
    addItem() {
      const item = this.createItem();

      // this.goodDataList.push(item);

      // this.$emit('update:goodDataList', this.goodDataList);
      this.$emit("update:goodDataList", this.goodDataList.concat(item));
    },
    onClickTaxApply() {
      const toIsTax = !this.isTaxApply;

      this.$emit("update:isTaxApply", toIsTax);

      if (toIsTax) {
        this.goodDataList.forEach((item) => item.setTaxPrice());
      } else {
        this.goodDataList.forEach((item) => item.clearTaxPrice());
      }
    },
    onClickDiscountApply() {
      if (!getCanConvertNumber(this.discountRate)) {
        this.$store.dispatch(SHOW_ALEALERT_ACTION.SHOW_ALERTRT_ACTION, "1~100까지 적용가능합니다.");
        return;
      }

      const nDiscountRate = parseFloat(this.discountRate);
      if (nDiscountRate == "" || nDiscountRate < 1 || nDiscountRate > 100) {
        this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, "1~100까지 적용가능합니다.");
        return;
      }

      this.$emit("update:isDiscountApply", !this.isDiscountApply);
    },
  },
};
</script>
