<template>
  <div class="area_view">
    <Fold title="견적서">
      <TableView>
        <template v-slot:body>
          <tr>
            <th>견적서번호</th>
            <td colspan="3">
              {{ itemData.estimateNo }}
            </td>
          </tr>
          <tr>
            <th>견적서명</th>
            <td>{{ itemData.estimateTitle }}</td>
            <th>견적일자</th>
            <td>{{ itemData.estimateDt | dateStringFormat }}</td>
          </tr>
          <tr>
            <th>공급사</th>
            <td class="td_text">
              {{ itemData.spplrCompanyNm }}
            </td>
            <th>사업자등록번호</th>
            <td>{{ itemData.spplrCompanyRgstNo }}</td>
          </tr>
          <tr>
            <th>공급사담당자</th>
            <td>{{ itemData.spplrMngrNm }}</td>
            <th>회사전화번호</th>
            <td>{{ itemData.spplrTelNo }}</td>
          </tr>
        </template>
      </TableView>
    </Fold>
    <GoodsDetailView title="견적" :itemData="itemData" :dataList="itemData.estimateItemList" />
    <div class="area_view">
      <TableView>
        <template v-slot:body>
          <tr>
            <th>비고</th>
            <td colspan="3" class="td_text" v-html="itemData.remark" />
          </tr>
        </template>
      </TableView>
    </div>
  </div>
</template>

<script>
import Fold from "@/components/common/fold/Fold";
import TableView from "@/components/shared/tableView/TableView";
import GoodsDetailView from "@/components/shared/goodsDetail/GoodsDetailView";

export default {
  name: "EstimateComplete",
  components: {
    Fold,
    TableView,
    GoodsDetailView,
  },
  props: {
    itemData: Object,
  },
};
</script>
