<template>
  <Popup popupType="tableType" :width="widthPopGoods">
    <div class="section_comm">
      <div class="head_section">
        <h3 class="tit_section">품목조회</h3>
      </div>
      <div class="body_section">
        <GoodsSearchPopupFilterBar
          :keywordList="keywordList"
          :defaultKeywordSelectedId="defaultKeywordSelectedId"
          :defaultKeywordInputText="defaultKeywordInputText"
          @onClickSearch="onClickSearch"
        />
        <Board
          :isScroll="true"
          scrollHeight="calc(100vh - 458px)"
          scrollMaxHeight="343px"
          scrollMinHeight="132px"
          :disableHover="true"
          :dataList="dataList"
          :currentPageIndex="currentPageIndex"
          :maxRowCount="defaultRowCount"
          :maxPaginationCount="maxPaginationCount"
          :totalPageCount="totalPageCount"
          @onChangePage="(pageIndex) => onChangePage(pageIndex)"
        >
          <!-- 
          :showPagination="true"
          :isScroll="true"
          scrollMaxHeight="342px"-->
          <template v-slot:colgroup>
            <col style="width: 52px" />
            <!-- 선택 -->
            <col style="width: 154px" />
            <!-- 품목코드 -->
            <col :style="isShowQuantity ? 'width: 214px;' : 'width: 284px;'" />
            <!-- 품목명 -->
            <col :style="isShowQuantity ? 'width: 154px;' : 'width: 208px;'" />
            <!-- 품목군 -->
            <col v-if="isShowQuantity" style="width: 124px" />
            <!-- 재고수량 -->
          </template>
          <template v-slot:tr>
            <th style="width: 52px" />
            <th style="width: 154px">품목코드</th>
            <th :style="isShowQuantity ? 'width: 214px;' : 'width: 284px;'">품목명</th>
            <th :style="isShowQuantity ? 'width: 154px;' : 'width: 208px;'">품목군</th>
            <th v-if="isShowQuantity" style="width: 124px">재고수량</th>
          </template>
          <template v-slot:rows>
            <template v-for="(item, index) in dataList">
              <GoodsSearchPopupLine
                :key="index"
                :rowData="item"
                :isShowQuantity="isShowQuantity"
                :radioSelectedSeq.sync="radioSelectedSeq"
                :goodsSeletedItem.sync="goodsSeletedItem"
              />
            </template>
          </template>
          <template v-slot:emptyList>
            <tr>
              <!-- <td colspan="5" class="td_empty h476"><span class="ico_exclam_error_t"></span>{{ emptyText }}에 대한 검색 결과가 없습니다.</td> -->
              <!-- <td colspan="5" class="td_empty h476">
                <span class="ico_exclam_error_t"></span>검색 결과가 없습니다.
              </td> -->
              <td colspan="5" class="td_empty">
                <div
                  class="inner_empty"
                  style="height: calc(100vh - 487px); max-height: 314px; min-height: 103px"
                >
                  <div class="txt_empty">
                    <span class="ico_koop ico_exclam_error_t" />품목에 대한검색 결과가 없습니다.<br />품목등록
                    페이지에서 품목을 추가해주세요.
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </Board>
      </div>
    </div>
    <div class="group_btn">
      <button type="button" class="btn_tertiary btn_medium" @click="onClickClose">취소</button>
      <button type="submit" class="btn_primary btn_medium" @click="onClickAdd">적용</button>
    </div>
  </Popup>
</template>

<script>
import Popup from "@/components/layout/popup/Popup";
import GoodsSearchPopupFilterBar from "./GoodsSearchPopupFilterBar";
import Board from "@/components/common/board/Board";
import GoodsSearchPopupLine from "./GoodsSearchPopupLine";

import { ALERT_ACTION } from "@/store/modules/alert/action";
import SimpleListMixin from "@/mixins/SimpleListMixin";

export default {
  components: {
    Popup,
    GoodsSearchPopupFilterBar,
    Board,
    GoodsSearchPopupLine,
    // CheckboxItem
  },
  mixins: [SimpleListMixin],
  props: {
    widthPopGoods: String,
    defaultRadioSelectedSeq: Number,
    apiPath: String,
    isShowQuantity: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      API_PATH: this.apiPath || this.$apiPath.ITEM,
      keywordList: [
        { id: "itemNm", code: "itemNm", desc: "품목명" },
        { id: "itemGroupNm", code: "itemGroupNm", desc: "품목군" },
      ],
      defaultKeywordSelectedId: "itemNm",
      radioSelectedSeq: this.defaultRadioSelectedSeq || null,
      goodsSeletedItem: {},
      autoDataList: [],
    };
  },
  beforeMount() {
    this.getDataInit();
  },
  methods: {
    getCurrentParams() {
      const params = {};

      if (this.defaultKeywordSelectedId && this.defaultKeywordInputText) {
        params[this.defaultKeywordSelectedId] = this.defaultKeywordInputText;
      }

      return params;
    },
    getFilterbarParams(objData) {
      const objCommon = this.getFilterbarParamsCommon(objData);

      return objCommon;
    },
    onClickClose() {
      this.$emit("onClickClose");
    },
    onClickAdd() {
      if (this.radioSelectedSeq === null) {
        this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, "품목을 선택해주세요.");
        return;
      }

      const itemData = this.dataList.find((item) => item.itemSeq === this.radioSelectedSeq);
      if (!itemData) {
        this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, "품목을 선택해주세요.");
        return;
      }

      this.$emit("onClickAdd", itemData);
    },
  },
};
</script>
