import GoodsWriteItemModel from "@/components/shared/goodsDetail/GoodsWriteItemModel";
import PhoneModel from "@/models/PhoneModel";

export default class EstimateNotCompleteModel {
  constructor() {
    this.objRaw = {};

    // 공급사 담당자에 공급사 대표자명 기본 노출.
    this.defaultSupplierManagerName = "";

    this.defaultTelNo = "";

    this.estimateTitle = ""; // 견적서명
    this.spplrMngrNm = ""; // 공급사담당자
    this.spplrTelNoModel = new PhoneModel("02"); // 전화번호.
    this.remark = ""; // 비고.

    this.discountRate = "0";

    this.goodsModelList = [];
  }

  setDefaultSupplierManagerName(name) {
    this.defaultSupplierManagerName = name;

    this.spplrMngrNm = name;
  }

  setDefaultTelNo(telNo) {
    this.defaultTelNo = telNo;

    this.spplrTelNoModel.setDataByString(telNo);
  }

  setData(obj) {
    this.objRaw = obj;

    const { estimateTitle, spplrMngrNm, spplrTelNo, remark, estimateItemList, discountRate } = obj;

    this.estimateTitle = estimateTitle || "";
    this.spplrMngrNm = spplrMngrNm || this.defaultSupplierManagerName || "";
    this.remark = remark || "";

    this.discountRate = discountRate ? String(discountRate) : "0";

    if (spplrTelNo) {
      this.spplrTelNoModel.setDataByString(spplrTelNo);
    } else if (this.defaultTelNo) {
      this.spplrTelNoModel.setDataByString(this.defaultTelNo);
    }

    if (estimateItemList && estimateItemList.length > 0) {
      this.goodsModelList = estimateItemList.map((item) => new GoodsWriteItemModel(item));
    }
  }

  getData() {
    let obj = {
      estimateTitle: this.estimateTitle,
      spplrMngrNm: this.spplrMngrNm,
      remark: this.remark,
      discountRate: this.discountRate,
      spplrTelNo: this.spplrTelNoModel.getIsValid() ? this.spplrTelNoModel.getTextFull() : "",
    };

    if (this.goodsModelList) {
      obj.estimateItemList = this.goodsModelList.map((item) => item.getData());
    }

    return Object.assign({}, this.objRaw, obj);
  }

  getAlertMessage() {
    if (!this.estimateTitle) return "견적서명을 확인해주세요.";
    if (!this.spplrMngrNm) return "공급사담당자를 확인해주세요.";
    if (!this.spplrTelNoModel.getIsValid()) return "전화번호를 확인해주세요.";

    if (this.goodsModelList.length === 0) return "품목을 추가해주세요.";

    let item;
    let alertMessage;

    for (let i = 0; i < this.goodsModelList.length; ++i) {
      item = this.goodsModelList[i];
      if (!item.quantity || item.quantity <= 0) return "품목의 수량을 확인해주세요.";
      if (!item.itemPrice || item.itemPrice <= 0) return "품목의 단가를 확인해주세요.";

      alertMessage = item.getAlertMessage();

      if (alertMessage) return alertMessage;
    }

    return null;
  }
}
