<template>
  <Fold :title="title + '상세품목'">
    <div class="area_view">
      <Board
        :disableHover="true"
        :dataList="dataList || []"
        :isScroll="true"
        scrollMaxHeight="342px"
        :showPagination="false"
      >
        <template v-slot:colgroup>
          <col style="width: 60px" />
          <!-- NO. -->
          <col style="width: 220px" />
          <!-- 품목 -->
          <col style="width: 170px" />
          <!-- 규격 -->
          <col style="width: 250px" />
          <!-- 수량 -->
          <col style="width: 180px" />
          <!-- 단가 -->
          <col style="width: 180px" />
          <!-- 금액 -->
          <col style="width: 180px" />
          <!-- 세액 -->
        </template>
        <template v-slot:tr>
          <th style="width: 60px">번호</th>
          <th style="width: 220px">품목명</th>
          <th style="width: 170px">규격</th>
          <th style="width: 250px">수량</th>
          <th style="width: 180px">단가</th>
          <th style="width: 180px">공급가액</th>
          <th style="width: 180px">세액</th>
        </template>
        <template v-slot:rows>
          <template v-for="(item, index) in dataList || []">
            <tr :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ item.itemNm }}</td>
              <td>{{ item.specification }}</td>
              <td>{{ item.quantity }} {{ item.quantityGubunCodeNm }}</td>
              <td>{{ item.currencyName }} {{ item.itemPrice | currency }}</td>
              <td>{{ item.currencyName }} {{ item.spplrPrice | currency }}</td>
              <td>{{ item.currencyName }} {{ item.taxPrice | currency }}</td>
            </tr>
          </template>
        </template>
        <template v-slot:emptyList>
          <tr>
            <td colspan="8" class="td_empty">{{ title }}상세품목이 없습니다.</td>
          </tr>
        </template>
      </Board>
      <TableView>
        <template v-slot:colgroup>
          <col style="width: 192px" />
          <col style="width: 428px" />
          <col style="width: 620px" />
        </template>
        <template v-slot:body>
          <tr>
            <th>세액</th>
            <td class="h71">
              {{ TaxBtnText }}
            </td>
            <td rowspan="2" class="no_padding">
              <dl class="list_total">
                <dt>공급가액</dt>
                <dd>{{ itemData | money(itemData.spplrPriceSum) }}</dd>
                <dt>할인</dt>
                <dd>(-) {{ itemData | money(isDiscountApply ? discountTotal : "0") }}</dd>
                <dt>세액</dt>
                <dd>{{ itemData | money(isTaxApply ? itemData.taxPriceSum : "0") }}</dd>
                <dt class="dt_total">
                  {{ title }}금액
                  <span class="txt_small">(공급가액{{ TotalSmallText }})</span>
                </dt>
                <dd class="dd_total">
                  <span class="txt_total">일금</span>
                  <span class="txt_korean">{{ sumKorean ? sumKorean + "원정" : "-" }}</span>
                  <span class="txt_price">({{ itemData | money(sum) }})</span>
                </dd>
              </dl>
            </td>
          </tr>
          <tr>
            <th>할인</th>
            <td class="h73">
              {{ DiscountBtnText }}
            </td>
          </tr>
        </template>
      </TableView>
    </div>
    <slot />
  </Fold>
</template>

<script>
import Fold from "@/components/common/fold/Fold";
import Board from "@/components/common/board/Board";
import TableView from "@/components/shared/tableView/TableView";
import { getMoneyToKorean, addCommaForMoney, getMoneyToNumber } from "@/utils/stringUtils";

import SumViewMixin from "@/mixins/SumViewMixin";

export default {
  components: {
    Fold,
    Board,
    TableView,
  },
  mixins: [SumViewMixin],
  props: {
    itemData: Object,
    dataList: Array,

    title: String,
  },
  computed: {
    TaxBtnText() {
      if (this.isTaxApply) {
        return "세액 적용";
      } else {
        return "세액 미적용";
      }
    },
    DiscountBtnText() {
      if (this.isDiscountApply) {
        return "할인 " + this.itemData.discountRate + "% 적용";
      } else {
        return "할인 미적용";
      }
    },
    TotalSmallText() {
      if (!this.isTaxApply && !this.isDiscountApply) {
        return "";
      } else {
        var discountText = "";
        var taxText = "";
        // if (this.isDiscountApply) {
        //   discountText = '-할인';
        // }
        if (this.isTaxApply) {
          taxText = "+세액";
        }
        const result = taxText;
        return result;
      }
    },
    sumKorean() {
      return getMoneyToKorean(this.sum);
    },
  },
};
</script>
