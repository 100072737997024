<template>
  <FilterBarBase :btnBasic="false" @onClickSearch="onClickSearch">
    <dl class="list_filter_full">
      <dt class="screen_out">검색어</dt>
      <dd>
        <div class="row_pack">
          <SelectBox name="keywordSelect" :dataList="keywordList" :value.sync="keywordSelectedId" />

          <Input class="w355" :value.sync="keywordInputText" placeholder="검색어를 입력하세요." />
          <button type="button" class="btn_secondary btn_medium" @click="onClickSearch">
            검색
          </button>
        </div>
      </dd>
    </dl>
  </FilterBarBase>
</template>

<script>
import FilterBarBase from "@/components/shared/filterBar/FilterBarBase";
import SelectBox from "@/components/common/selectBox/SelectBox";
import Radio from "@/components/common/radio/Radio";
import Input from "@/components/common/input/Input";

export default {
  name: "GoodsSearchPopupFilterBar",
  components: {
    FilterBarBase,
    Radio,
    Input,
    SelectBox,
  },
  props: {
    keywordList: Array,
    defaultKeywordSelectedId: String,
    defaultKeywordInputText: String,
  },
  data() {
    return {
      keywordSelectedId: this.defaultKeywordSelectedId,
      keywordInputText: this.defaultKeywordInputText,
    };
  },
  methods: {
    resetData() {
      this.keywordSelectedId = this.defaultKeywordSelectedId;
      this.keywordInputText = this.defaultKeywordInputText;
    },
    onClickSearch(e) {
      const ret = {
        keywordSelectedId: this.keywordSelectedId,
        keywordInputText: this.keywordInputText.trim(),
      };

      this.$emit("onClickSearch", ret);
    },
  },
};
</script>
