<template>
  <div class="area_view">
    <Fold title="견적서">
      <TableView>
        <template v-slot:body>
          <tr>
            <th>견적서번호</th>
            <td colspan="3">
              {{ itemData.estimateNo }}
            </td>
          </tr>
          <tr>
            <th>견적서명 <span class="ico_purchase ico_star">필수항목</span></th>
            <td>
              <Input
                placeholder="견적서명을 입력하세요."
                :value.sync="model.estimateTitle"
                data-cy="estimateName"
              />
            </td>
            <th>견적일자</th>
            <td>{{ itemData.estimateDt }}</td>
          </tr>
          <tr>
            <th>공급사</th>
            <td class="td_text">
              {{ loginCompanyName }}
            </td>
            <th>사업자등록번호</th>
            <td>{{ loginCompanyNo }}</td>
          </tr>
          <tr>
            <th>공급사담당자 <span class="ico_purchase ico_star">필수항목</span></th>
            <td>
              <Input
                placeholder="담당자명을 입력하세요."
                :value.sync="model.spplrMngrNm"
                :isOnlyEngKor="true"
                :maxLength="MAX_LENGTH_MANAGER_NAME"
              />
            </td>
            <th>회사전화번호 <span class="ico_purchase ico_star">필수항목</span></th>
            <td>
              <!-- <Input placeholder="전화번호를 입력하세요." :value.sync="estimateInfoDataList.phone" /> -->
              <InputPhone
                :useMobile="false"
                :useArea="true"
                :phone0.sync="model.spplrTelNoModel.phone0"
                :phone1.sync="model.spplrTelNoModel.phone1"
                :phone2.sync="model.spplrTelNoModel.phone2"
              />
            </td>
          </tr>
        </template>
      </TableView>
    </Fold>
    <GoodsDetailAdd
      ref="goodsDetailAdd"
      title="견적"
      :canAdd="true"
      :canDiscountAndTax="true"
      :canModifyItem="true"
      :GoodsDetailAdd="true"
      :currencyName="itemData.currencyName"
      :isTaxApply.sync="isTaxApply"
      :isDiscountApply.sync="isDiscountApply"
      :discountRate.sync="model.discountRate"
      :goodDataList.sync="model.goodsModelList"
      @onClickRemove="onClickRemove"
      @onClickGoodsSearch="(item, func) => $emit('onClickGoodsSearch', item, func)"
    >
      <div class="area_view">
        <TableView>
          <template v-slot:body>
            <tr>
              <th>비고</th>
              <td colspan="3">
                <Textarea
                  placeholder="비고를 입력하세요"
                  :max="MAX_LENGTH_MEMO"
                  :isCount="true"
                  :value.sync="model.remark"
                />
              </td>
            </tr>
          </template>
        </TableView>
      </div>
    </GoodsDetailAdd>
  </div>
</template>

<script>
import Fold from "@/components/common/fold/Fold";
import TableView from "@/components/shared/tableView/TableView";
import Input from "@/components/common/input/Input";
import GoodsDetailAdd from "@/components/shared/goodsDetail/GoodsDetailAdd";
import Textarea from "@/components/common/textarea/Textarea";
import InputPhone from "@/components/common/input/InputPhone";

import { getCanConvertNumber } from "@/utils/numberUtils";

import { ALERT_ACTION } from "@/store/modules/alert/action";
import EstimateNotCompleteModel from "./EstimateNotCompleteModel";

import { MAX_LENGTH_MEMO, MAX_LENGTH_MANAGER_NAME } from "@/constants/commonTextLength";

export default {
  components: {
    Fold,
    TableView,
    Input,
    GoodsDetailAdd,
    Textarea,
    InputPhone,
  },
  props: {
    itemData: Object,

    model: EstimateNotCompleteModel,

    loginCompanyName: String,
    loginCompanyNo: String,
  },
  data() {
    return {
      isTaxApply: true,
      isDiscountApply: false,
    };
  },
  computed: {
    MAX_LENGTH_MEMO() {
      return MAX_LENGTH_MEMO;
    },
    MAX_LENGTH_MANAGER_NAME() {
      return MAX_LENGTH_MANAGER_NAME;
    },
  },
  beforeMount() {
    const { taxPriceSum, discountRate } = this.itemData;

    if (taxPriceSum && getCanConvertNumber(taxPriceSum)) {
      const nTaxPriceSum = parseInt(taxPriceSum);

      if (nTaxPriceSum > 0) {
        this.isTaxApply = true;
      }
    }

    if (discountRate && getCanConvertNumber(discountRate)) {
      const nDiscountRate = parseFloat(discountRate);

      if (nDiscountRate > 0) {
        this.isDiscountApply = true;
      }
    }
  },
  methods: {
    getPriceSum() {
      return this.$refs.goodsDetailAdd.getPriceSum();
    },
    onClickRemove(indexToRemove) {
      this.model.goodsModelList = this.model.goodsModelList.filter(
        (item, index) => index !== indexToRemove,
      );
    },
  },
};
</script>
