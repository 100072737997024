<template>
  <tr :class="{ tr_select: isSelected }">
    <td>
      <RadioItem :id="rowData.itemSeq" name="goodsSelect" :selectedId.sync="radioSelectedSeqSync" />
    </td>
    <td>{{ rowData.itemCode }}</td>
    <td class="td_ellip" :data-ellip="rowData.itemNm">
      {{ rowData.itemNm }}
    </td>
    <td class="td_ellip" :data-ellip="rowData.itemGroupNm">
      {{ rowData.itemGroupNm }}
    </td>
    <td v-if="isShowQuantity">
      {{ amountQuantity }}
    </td>
  </tr>
</template>
<script>
import RadioItem from "@/components/common/radio/RadioItem";
import { getCountWithCurrency } from "@/utils/spUtils";

export default {
  name: "GoodsSearchPopupLine",
  components: {
    RadioItem,
  },
  props: {
    // checkedNames: Array,
    rowData: Object,
    radioSelectedSeq: Number,
    isShowQuantity: Boolean,
  },
  computed: {
    amountQuantity() {
      return getCountWithCurrency(this.rowData.quantity, this.rowData.quantityGubunCodeNm);
    },
    radioSelectedSeqSync: {
      get() {
        return this.radioSelectedSeq;
      },
      set(value) {
        this.$emit("update:radioSelectedSeq", value);
      },
    },
    isSelected() {
      const ret = this.radioSelectedSeqSync === this.rowData.itemSeq;

      return !!ret;
    },
    //   isSelected() {
    //     const ret = this.checkedNames.find( item => String( item ) === String( this.rowData.id ) );

    //     return !!ret;
    //   },
  },
};
</script>
